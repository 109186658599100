import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Content from "../../../../content/produk/controller/multimedia/NOVASTAR TB6/Product-Page.json";
import Datasheet from "../../../../content/produk/controller/multimedia/NOVASTAR TB6/datasheet-novastar-tb6.pdf";
import ProductImage from "../../../../images/product/controller/multimedia/NOVASTAR TB6/Product-Summary-NOVASTAR-TB6.jpg";
const novastartb6 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Multimedia Novastar TB6</h1>
                    <Row>
                        <Col>
                            <Image
                                src={ProductImage}
                                fluid
                                alt={"Gambar modul"}
                            />
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p>
                            Taurus series products can be widely used in LED
                            commercial display field, such as bar screen, chain
                            store screen, advertising machine, mirror screen,
                            retail store screen, door head screen, on board
                            screen and the screen requiring no PC.
                        </p>
                        <ul>
                            <li>
                                Pixel capacity up to 1,300,000, with the maximum
                                width of 4096 pixels and maximum height of 1920
                                pixels.
                            </li>
                            <li>
                                Supports 2-primary 2-standby Ethernet port
                                redundant mechanism.
                            </li>
                            <li>
                                Supports dual-Wi-Fi, and features Wi-Fi AP and
                                Wi-Fi Sta functions.
                            </li>
                            <li>Supports Gigabit wired network.</li>
                            <li>Supports stereo audio output.</li>
                            <li>Supports HDMI Loop.</li>
                            <li>Supports HDMI input mode.</li>
                            <li>
                                Supports HDMI input full-screen self-adaptive
                                display.
                            </li>
                            <li>
                                Supports manual and timing switching between
                                synchronous and asynchronous modes.
                            </li>
                            <li>
                                2 USB ports allows for playback of media
                                imported from USB drives.
                            </li>
                            <li>
                                1 Onboard brightness sensor port supports
                                automatic and scheduled smart brightness
                                adjustment.
                            </li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

novastartb6.propTypes = {
    siteTitle: PropTypes.string,
};

novastartb6.defaultProps = {
    siteTitle: ``,
};
export default novastartb6;
